import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";

const HeroImage = () => {
  return (
    <h1>
      <div class="relative md:block text-white">
        <StaticImage src="../images/company/company_top.png" />

        <div
          class="absolute top-0 text-left"
          style={{ marginTop: "8.5%", marginLeft: "10%" }}
        >
          <div class="text-sm md:text-2xl lg:text-3xl font-bold">会社概要</div>
          <div class="text-xs md:text-base lg:text-lg font-semibold">
            COMPANY
          </div>
        </div>
      </div>
    </h1>
  );
};

const CompanyPage = () => {
  return (
    <Layout>
      <div class="container mx-auto">
        <div class="mx-auto text-center">
          <HeroImage />

          <div class="w-full">
            <div class="w-11/12 md:w-10/12 mx-auto text-left text-sm md:text-base md:px-2">
              <h2 class="py-8 text-center">
                <div class="text-darkblue text-2xl font-semibold">会社概要</div>
                <div class="text-secondary text-lg">COMPANY</div>
              </h2>

              <div class="border border-secondary text-primary mb-24">
                <dl>
                  <div class="grid grid-cols-dl gap-0">
                    <dt class="dt">社 名</dt>
                    <dd class="dd">株式会社TAC</dd>
                  </div>

                  <div class="grid grid-cols-dl gap-0">
                    <dt class="dt">所在地</dt>
                    <dd class="dd">
                      〒601-8174 　京都市南区上鳥羽清井町 234 番地
                    </dd>
                  </div>

                  <div class="grid grid-cols-dl gap-0">
                    <dt class="dt">資本金</dt>
                    <dd class="dd">2,000 万円</dd>
                  </div>

                  <div class="grid grid-cols-dl gap-0">
                    <dt class="dt">設 立</dt>
                    <dd class="dd">2002年3月</dd>
                  </div>

                  <div class="grid grid-cols-dl gap-0">
                    <dt class="dt">代表取締役会長</dt>
                    <dd class="dd">原田正敏</dd>
                  </div>

                  <div class="grid grid-cols-dl gap-0">
                    <dt class="dt">取締役社長</dt>
                    <dd class="dd">出口弘</dd>
                  </div>

                  <div class="grid grid-cols-dl gap-0">
                    <dt class="dt">従業員</dt>
                    <dd class="dd">16名(非常勤2名)</dd>
                  </div>

                  <div class="grid grid-cols-dl gap-0">
                    <dt class="dt">金融機関</dt>
                    <dd class="dd">京都信用金庫・京都銀行・りそな銀行</dd>
                  </div>

                  <div class="grid grid-cols-dl gap-0">
                    <dt class="dt">事業内容</dt>
                    <dd class="dd">
                      ファクトリーオートメーション事業
                      <ul class="list-disc">
                        <li class="ml-6">
                          産業用省力製造設備の設計から製造・据付まで
                        </li>
                        <li class="ml-6">各種機械設計,電気制御</li>
                      </ul>
                      <span class="pt-2">機械加工部品の製造</span>
                    </dd>
                  </div>

                  <div class="grid grid-cols-dl gap-0">
                    <dt class="dt">主要取引先</dt>
                    <dd class="dd">
                      <ul>
                        <li class="pt-1">株式会社エコロジーネット</li>
                        <li class="pt-1">三共株式会社</li>
                        <li class="pt-1">GS ユアサグループ</li>
                        <li class="pt-1">株式会社 椿本チエイン</li>
                        <li class="pt-1">TMT マシナリー株式会社</li>
                        <li class="pt-1">パナソニックグループ</li>
                        <li class="pt-1">
                          ヌヴォトンテクノロジージャパン株式会社
                        </li>
                        <li class="pt-1">三菱商事テクノス株式会社</li>
                      </ul>
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>

          {/* Photo */}
          <div class="w-full">
            <div className="flex flex-col md:flex-row md:justify-around mb-12">
              <div className="relative mr-1">
                <StaticImage src="../images/company/hq.png" />
              </div>
              <div className="relative ml-1">
                <StaticImage src="../images/company/factory.png" />
              </div>
            </div>
          </div>

          {/* ACCESS */}
          <div class="w-full bg-secondary text-white mb-24">
            <h2 class="pt-8 text-center text-2xl font-semibold">ACCESS</h2>

            <div
              class="w-11/12 md:w-10/12 mx-auto pt-8 pb-12"
              style={{ height: "45vh" }}
            >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6539.679337949!2d135.739717!3d34.960627!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x600105ef04a1a297%3A0x95e3cabd492cd0b9!2z77yI5qCq77yJ77y077yh77yj!5e0!3m2!1sja!2sjp!4v1670217610559!5m2!1sja!2sjp"
                width="100%"
                height="100%"
                style={{ border: "0" }}
                allowfullscreen=""
                loading="lazy"
                title="Map"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>

          {/* 導入実績  */}
          <div class="w-11/12 md:w-10/12 mx-auto mb-8">
            <h2 class="pb-8">
              <div class="text-darkblue text-2xl font-semibold">導入実績</div>
            </h2>

            <div class="grid grid-cols-1">
              <ul className="divide-y divide-secondary py">
                <li />
                <li className="py-4">・バッテリー生産工程設備</li>
                <li className="py-4">・鉛極板生産設備</li>
                <li className="py-4">・リチウム電池生産設備</li>
                <li className="py-4">・自動車部品組立設備</li>
                <li className="py-4">・ラミネイティング設備</li>
                <li className="py-4">・半導体デバイス検査工程関連設備</li>
                <li className="py-4">・LED検査工程設備</li>
                <li className="py-4">・家電製品リサイクル設備</li>
                <li className="py-4">・精密部品/治具等の加工品</li>
                <li />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CompanyPage;
